import { request, METHOD } from '@/utils/request'
import { addurl } from '@/utils/util'
import { baseURL } from '@/config/proxyconfig'

const requesturl = baseURL + '/api/theme/'
    ///user/getlist/{index}/{pagesize}
    /**
     * 获取列表页
     * @param page
     * @param pagesize
     * @param isonline  0-下架，1-上架
     * @param keyword
     * @param order 排序字段
     * @param sort 排序方式 0-降序，1-升序
     * @param type p-获取部分字段
     */
export async function getAllLevelTheme(id) {
    return request(addurl('getAllLevelTheme', requesturl) + '?pid=' + id, METHOD.GET)
}


export default {
    getAllLevelTheme,
}
