
export function isDef(v) {
  return v !== undefined && v !== null;
}

/**
 * Remove an item from an array.
 */
export function remove(arr, item) {
  if (arr.length) {
    const index = arr.indexOf(item);
    if (index > -1) {
      return arr.splice(index, 1);
    }
  }
}

export function isRegExp(v) {
  return _toString.call(v) === "[object RegExp]";
}

const _toString = Object.prototype.toString;

export const addurl = function (method, requesturl) {
  return requesturl + method;
};

export const replaceNew = function (name, key) {
  if (!key) {
    key = ''
  }
  let reg = new RegExp(key,'g')//g代表全部
  if (!!name && key.length > 0 && name.indexOf(key) >= 0)
    return name.replace(reg, "<font>" + key + "</font>");
  return name;
};
export const replaceNewPosition = function (name, key) {
  if (!key) {
    key = ''
  }
  let reg = new RegExp(key,'g')//g代表全部
  if (!!name && key.length > 0 && name.indexOf(key) >= 0)
    return name.replace(reg, "<font class='first-font-position'>" + key + "</font>");
  return name;
};
export const replaceNameAndValue = function (name, key) {
  if (!key) {
    key = ''
  }
  let reg = new RegExp(key,'g')//g代表全部
  if (!!name && key.length > 0 && name.indexOf(key) >= 0)
    return name.replace(reg, "<span class='index-name-style'>" + key + "</span>");
  return name;
};

export const replacePath = function (path, ptype, vtag) {
  ptype = ptype || "1";
  vtag = vtag || "0";
  
  if(path!==null&&!!path&&path.length>0&& path.indexOf('/showpic/show')===-1){
    return (
      process.env.VUE_APP_API_BASE_URL +
      "/showpic/show?vpath=" +
      replaceAll(path) +
      "&vtag=" +
      vtag +
      "&ptype=" +
      ptype
    );
  }else{
    if(path!==null&&path.length>0){
      return (        
        process.env.VUE_APP_API_BASE_URL +replaceAll(path)
      );
    }else{
      return ( 
        process.env.VUE_APP_API_BASE_URL +
        "/showpic/show?vpath=" +
        replaceAll(path) +
        "&vtag=" +
        vtag +
        "&ptype=" +
        ptype
      )
    }
  }
  
};

export const replaceVideoPath = function (path) {
  return (
    process.env.VUE_APP_API_BASE_URL +
    "/showvora/playVideo?path=" +
    replaceAll(path)
  );
};

export const replaceAudioPath = function (path) {
  return (
    process.env.VUE_APP_API_BASE_URL +
    "/showvora/playAudio?path=" +
    replaceAll(path)
  );
};

export const replaceFilePath = function (path) {
  if (path && path.length > 0) {
    return (
      process.env.VUE_APP_API_BASE_URL +
      "/showpdf/show?vpath=" +
      replaceAll(path) +
      "&vtag=1"
    );
  }

  return path;
};


export const replaceFilePartPath = function (startPage, endPage, path) {
  if (path && path.length > 0) {
    return (
      process.env.VUE_APP_API_BASE_URL +
      "/showpdf/getSplitFile?startPage=" +
      startPage +
      "&endPage=" +
      endPage +
      "&vpath=" +
      replaceAll(path)
    );
  }
  return path;
};

export const replaceAll = function (path) {
  if (path && path.length > 0 && path.indexOf("\\") >= 0) {
    //path.replace('\\','/')
    path = replaceAll(path.replace("\\", "/"));
  }
  return path;
};

export const silceTime = function (time) {
  if (time) {
    var timeArr = time.split(" ");
    return timeArr[0];
  }
  return time;
};
export const stringToTime = function (date) {
  var year = date.getFullYear();//年
  var month = date.getMonth();//月
  var day = date.getDate();//日
  var hours = date.getHours();//时
  var min = date.getMinutes();//分
  var second = date.getSeconds();//秒
  return year + "-" +
      ((month + 1) > 9 ? (month + 1) : "0" + (month + 1)) + "-" +
      (day > 9 ? day : ("0" + day)) + " " +
      (hours > 9 ? hours : ("0" + hours)) + ":" +
      (min > 9 ? min : ("0" + min)) + ":" +
      (second > 9 ? second : ("0" + second));
};

export const changeType = function (type) {
  let yearCount = "";
  if (type) {
    switch (type) {
      case "0":
        yearCount = "周刊"; //周刊
        break;
      case "1":
        yearCount = "旬刊"; //旬刊
        break;
      case "2":
        yearCount = "半月刊"; //半月刊
        break;
      case "3":
        yearCount = "月刊"; //月刊
        break;
      case "4":
        yearCount = "双月刊"; //双月刊
        break;
      case "5":
        yearCount = "季刊"; //季刊
        break;
      case "6":
        yearCount = "半年刊"; //半年刊
        break;
      case "7":
        yearCount = "年刊"; //年刊
        break;
      default:
        yearCount = "";
        break;
    }
  }
  return yearCount;
};

export {
}
