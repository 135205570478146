import {
    request,
    METHOD
  } from '@/utils/request'
  import {
    addurl
  } from '@/utils/util'
  import {
    baseURL
  } from '@/config/proxyconfig'
  const account = baseURL + '/api/foot/'
  
  /**
   * 查询
   */
   export async function getInfo(params) {
    return request(
      addurl("getInfo", account) + '?keyName=' + params,
      METHOD.GET,
    );
  }
  
//   export default {
//     getList,
//   }
  