<template>
	<div class="layout">
		<a-layout>
			<a-layout-header>
				<LayoutHeader @searchEditor="searchEditor" />
			</a-layout-header>
			<a-layout-content>
				<router-view ref="editor" />
			</a-layout-content>
			<a-layout-footer>
				<LayoutFooter />
			</a-layout-footer>
		</a-layout>
	</div>
</template>
<script>
import LayoutHeader from "../components/layout/Header.vue"
import LayoutFooter from "../components/layout/Footer.vue"
export default {
	name: "Home",
	components: { LayoutHeader, LayoutFooter },
	methods:{
		searchEditor(value) {
			this.$refs.editor.searchEditor(value)
		}
	}
};
</script>
<style lang="less" scoped>
</style>
