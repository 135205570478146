<template functional>
  <a-sub-menu :key="props.menuInfo.id" class="header" popupClassName="hearMenu2">
    <span slot="title">
      <router-link
        :title="props.menuInfo.themename"
        :to="{ name: props.menuInfo.router, params: { id: props.menuInfo.childrenList[0].id } }"
      >
        <div style="text-align:center" class="family-title" :class="{'header-sencend-child-class':props.menuInfo.themename === '本草文化工程'}">
          <span>{{ props.menuInfo.themename }}</span>
        </div>
      </router-link>
    </span>
    <template v-for="item in props.menuInfo.childrenList">
      <a-menu-item  :key="item.id">
        <!-- 需要三级或更多级解开注释 -->
      <!-- <a-menu-item v-if="!item.childrenList" :key="item.id"> -->
        <router-link
          :title="item.themename"
          :to="{ name: item.router, params: { id: item.childrenList?item.childrenList[0].id:item.id } }"
        >
          <div style="text-align:center" class="menu-style-list header-childrenList-class family-title" :title="item.themename">
            {{ item.themename }}
          </div>
        </router-link>
      </a-menu-item>
      <!-- 需要三级或更多级解开注释 -->
      <!-- <sub-menu v-else :key="item.key" :menu-info="item" /> -->
    </template>
  </a-sub-menu>
</template>
<script>
export default {
  name: "SubMenu",
  props: ["menuInfo"],
  data() {
    return {
      routerName: "",
    };
  },
  watch: {
    $route: {
      immediate: true, // 一旦监听到路由的变化立即执行
      handler(to) {
        let name = to.name;
        this.routerName = name;
      },
    },
  },
  methods: {
    goFirstRouter(item) {
      this.$router.push({
        name: item.router,
      });
      let name = this.routerName;
      if (name !== "Index" && name !== "NewsIndex" && name !== "BookIndex") {
        this.$router.push({
          name: item.router,
          params: { id: item.childrenList[0].id },
        });
      }
      // this.$emit("getFirstId", list[0].id);
    },
  },
};
</script>
