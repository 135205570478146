<template>
  <div id="app">
    <router-view :key="key" />
  </div>
</template>
<script>
// import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN"
export default {
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      // zh_CN,
      isRoutesAlive: true, //控制视图是否显示的变量
    };
  },
  mounted() {
    if (this._isMobile()) {
    //   alert("手机端");
      window.open("http://www.cptcm.com:8008/", "_self");
    } 
  },
  created() {
    this.setHtmlTitle();
  },
  watch: {
    $route() {
      this.setHtmlTitle();
    },
  },
  computed: {
    key() {
      return this.$route.name
        ? this.$route.name + new Date()
        : this.$route + new Date();
    },
  },
  methods: {
    //判断是手机端还是pc短
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    reload() {
      this.isRoutesAlive = false; //先关闭
      this.$nextTick(function () {
        this.isRoutesAlive = true; //再打开
      });
    },
    setHtmlTitle() {
      // const route = this.$route
      // const meta = route.path === '/' ? '首页' : route.matched[route.matched.length - 1].meta
      document.title = process.env.VUE_APP_NAME;
      // document.title = process.env.VUE_APP_NAME + ' | ' + meta.title
      // zh_CN
    },
  },
};
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2a2e36;
  height: 100%;
}
</style>

