import axios from "axios"
import Cookie from "js-cookie"

// 跨域认证信息 header 名
const xsrfHeaderName = "token"
const xsrfCookieName = 'kdmstd'
axios.defaults.timeout = 60000
    // 表示跨域请求时是否需要使用凭证
axios.defaults.withCredentials = true
axios.defaults.xsrfHeaderName = xsrfHeaderName
axios.defaults.xsrfCookieName = xsrfCookieName
    // 认证类型
const AUTH_TYPE = {
    BEARER: "Bearer",
    BASIC: "basic",
    AUTH1: "auth1",
    AUTH2: "auth2",
}

// http method
const METHOD = {
    GET: "get",
    POST: "post",
    DELETE: "delete",
    PUT: "put",
}

/**
 * axios请求
 * @param url 请求地址
 * @param method {METHOD} http method
 * @param params 请求参数
 * @returns {Promise<AxiosResponse<T>>}
 */
async function request(url, method, params) {
    axios.defaults.headers[xsrfHeaderName] = JSON.parse(localStorage.getItem("loginToken"))
    switch (method) {
        case METHOD.GET:
            return axios.get(url, { params })
        case METHOD.POST:
            axios.defaults.headers["Content-Type"] = "application/json"
            // axios.defaults.headers["Cookie"] = "777"
            // axios.defaults.headers["test"] = "888"
            return axios.post(url, params)
        case METHOD.DELETE:
            return axios.delete(url, params)
        case METHOD.PUT:
            axios.defaults.headers["Content-Type"] = "application/json"
            return axios.put(url, params)
        default:
            return axios.get(url, { params })
    }
}

/*
 * 设置认证信息
 * @param auth {Object}
 * @param authType {AUTH_TYPE} 认证类型，默认：{AUTH_TYPE.BEARER}
 */
function setAuthorization(auth, authType = AUTH_TYPE.BEARER) {
    switch (authType) {
        case AUTH_TYPE.BEARER:
            //Cookie.set(xsrfHeaderName, 'Bearer ' + auth.token, { expires: auth.expireAt })
            Cookie.set(xsrfCookieName, auth.token, { expires: auth.expireAt })
                //Cookie.set(xsrfHeaderName, auth.token, { expires: auth.expireAt });
            axios.defaults.headers[xsrfHeaderName] = auth.token
            break
        case AUTH_TYPE.BASIC:
        case AUTH_TYPE.AUTH1:
        case AUTH_TYPE.AUTH2:
        default:
            break
    }
}

/**
 * 移出认证信息
 * @param authType {AUTH_TYPE} 认证类型
 */
function removeAuthorization(authType = AUTH_TYPE.BEARER) {
    switch (authType) {
        case AUTH_TYPE.BEARER:
            Cookie.remove(xsrfCookieName)
            break
        case AUTH_TYPE.BASIC:
        case AUTH_TYPE.AUTH1:
        case AUTH_TYPE.AUTH2:
        default:
            break
    }
}

/**
 * 检查认证信息
 * @param authType
 * @returns {boolean}
 */
function checkAuthorization(authType = AUTH_TYPE.BEARER) {
    switch (authType) {
        case AUTH_TYPE.BEARER:
            if (Cookie.get(xsrfCookieName)) {
                return true
            }
            break
        case AUTH_TYPE.BASIC:
        case AUTH_TYPE.AUTH1:
        case AUTH_TYPE.AUTH2:
        default:
            break
    }
    return false
}

/**
 * 加载 axios 拦截器
 * @param interceptors
 * @param options
 */
function loadInterceptors(interceptors, options) {
    const { request, response } = interceptors
    // 加载请求拦截器
    request.forEach((item) => {
            let { onFulfilled, onRejected } = item
            if (!onFulfilled || typeof onFulfilled !== "function") {
                onFulfilled = (config) => config
            }
            if (!onRejected || typeof onRejected !== "function") {
                onRejected = (error) => Promise.reject(error)
            }
            axios.interceptors.request.use(
                (config) => onFulfilled(config, options),
                (error) => onRejected(error, options),
            )
        })
        // 加载响应拦截器
    response.forEach((item) => {
        let { onFulfilled, onRejected } = item
        if (!onFulfilled || typeof onFulfilled !== "function") {
            onFulfilled = (response) => response
        }
        if (!onRejected || typeof onRejected !== "function") {
            onRejected = (error) => Promise.reject(error)
        }
        axios.interceptors.response.use(
            (response) => onFulfilled(response, options),
            (error) => onRejected(error, options)
        )
    })
}

export {
    METHOD,
    AUTH_TYPE,
    request,
    setAuthorization,
    removeAuthorization,
    checkAuthorization,
    loadInterceptors,
}