import {
    request,
    METHOD
} from '@/utils/request'
import {
    addurl
} from '@/utils/util'
import {
    baseURL
} from '@/config/proxyconfig'

const requesturl = baseURL + '/api/theme/'
const noteRequesturl = baseURL + '/api/note/'
const requesturlAD = baseURL + '/api/ad/'

//根据任意的父节点ID获取下级所有栏目
export async function getAllLevelTheme(id) {
    return request(addurl('getAllLevelTheme', requesturl) + '?pid=' + id, METHOD.GET)
}
//根据栏目ID获取新闻列表
export async function getNoteList(params) {
    return request(addurl('getNoteList', noteRequesturl) + '/' + params.themeId + '/' + params.pageNumber + '/' + params.pageSize, METHOD.GET)
}
//根据新闻ID获得新闻正文
export async function getNoteContentByNoteId(id) {
    return request(addurl('getNoteContentByNoteId', noteRequesturl) + '/' + id, METHOD.GET)
}
//查询新闻列表
export async function getList(params) {
    return request(addurl('getList', noteRequesturl) + '/' + params.pageNumber + '/' + params.pageSize + '?query=' + params.searchValue, METHOD.GET)
}
//查询广告轮播图
export async function getAddList(params) {
    return request(addurl('getAddList', requesturlAD)  + '/' + params.pageNumber +  '/' + params.pageSize + '?type=' + params.type,
        METHOD.GET)
}
export default {
    getAllLevelTheme,
}