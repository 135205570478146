export default {
    namespaced: true,
    state: {
        themeList: [],
        currentPageNew:[]
    },
    getters: {
        themeList: state => state.themeList,
        currentPageNew: state => state.currentPageNew,
    },
    mutations: {
        savethemeList(state, list) {
            state.themeList = list;
        },
        savecurrentPage(state, list) {
            state.currentPageNew = list;
        }
    },
};