import PageView from '../views/Home.vue'

const routes = [
    // 首页
    {
        path: "/",
        redirect: '/home/index'
    },
    {
        path: '/home',
        component: PageView,
        children: [
            {
                path: 'index',
                name: 'Index',
                component: () => import(/* webpackChunkName: "homeindex" */ "../views/home/Index.vue"),
            }
        ]
    },
    //关于我们
    // {
    //     path: '/about',
    //     component: PageView,
    //     children: [
    //         {
    //             path: 'about',
    //             name: 'About',
    //             component: () => import( "../views/about/About.vue"),
    //         }
    //     ]
    // },
    //公司概况
    {
        path: '/companyOverview',
        component: PageView,
        children: [
            {
                path: 'companyOverview/:id',
                name: 'CompanyOverview',
                component: () => import( "../views/companyOverview/companyOverview.vue"),
            },
            {
                path: 'companyDetails/:doi',
                name: 'CompanyDetails',
                component: () => import( "../views/companyOverview/companyDetails.vue"),
            }
        ]
    },
    //新闻资讯
    {
        path: '/news',
        component: PageView,
        children: [
            {
                path: "newsIndex",
                name: "NewsIndex",
                component: () => import("../views/news/NewsIndex.vue"),
            },
            {
                path: "newsList/:id",
                name: "NewsList",
                component: () => import("../views/news/NewsList.vue"),
            },
            {
                path: "newsDetail/:doi",      
                name: "NewsDetail",
                component: () => import("../views/news/NewsDetail.vue"),
            },
            {
                path: "newsSearch",      
                name: "NewsSearch",
                component: () => import("../views/news/NewsSearch.vue"),
            },
        ],
    },
    //图书展示
    {
        path: '/book',
        component: PageView,
        children: [
            {
                path: "bookIndex",
                name: "BookIndex",
                component: () => import("../views/book/BookIndex.vue"),
            },
            {
                path: "bookList",
                name: "BookList",
                component: () => import("../views/book/BookList.vue"),
            },
            {
                path: "bookDetail/:doi",
                name: "BookDetail",
                component: () => import("../views/book/BookDetail.vue"),
            },
        ],
    },
    // 旗下品牌
    {
        path: '/brand',
        component: PageView,
        children: [
            {
                path: "brandIndex/:id",
                name: "BrandIndex",
                component: () => import("../views/brand/BrandIndex.vue"),
            },
            {
                path: "brandIndexDetails/:doi",
                name: "BrandIndexDetails",
                component: () => import("../views/brand/BrandIndexDetails.vue"),
            },
        ],
    },
    // 期刊年鉴
    {
        path: '/journal',
        component: PageView,
        children: [
            {
                path: "journalIndex/:id",
                name: "JournalIndex",
                component: () => import("../views/journal/JournalIndex.vue"),
            },
            {
                path: "journalIndexDetails/:doi",
                name: "JournalIndexDetails",
                component: () => import("../views/journal/journalIndexDetails.vue"),
            }
        ],
    },
    //编辑风采
    {
        path: '/editor',
        component: PageView,
        children: [
            {
                path: "editorIndex",
                name: "EditorIndex",
                component: () => import("../views/editor/EditorIndex.vue"),
            },
            {
                path: "editorDetails/:doi",
                name: "EditorDetails",
                component: () => import("../views/editor/editorDetails.vue"),
            },
        ],
    },
    //联系我们
    {
        path: '/contact',
        component: PageView,
        children: [
            {
                path: 'contact/:id',
                name: 'Contact',
                component: () => import( "../views/contact/Contact.vue"),
            },
            {
                path: 'contactDetails/:id',
                name: 'ContactDetails',
                component: () => import( "../views/contact/contactDetails.vue"),
            },
            {
                path: 'downLoadEar/:id',
                name: 'DownLoadEar',
                component: () => import( "../views/contact/downLoadEar.vue"),
            }
        ]
    },
]

export default routes 