// import { resolve } from 'core-js/fn/promise'
// import {logout} from '@/services/user'
// import Cookie from "js-cookie"; //登录之后解开
// import {
//   setAuthorization
// } from "@/utils/request";
// import { nextTick } from 'vue/types/umd'
// 401拦截
const resp401 = {
  /**
   * 响应数据之前做点什么
   * @param response 响应对象
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {*}
   */
  onFulfilled(response, options) {
    const {
      message
    } = options;
    if (response.status === 401) {
      message.destroy();
      message.error("无此接口权限");
    }
    return response;
  },
  /**
   * 响应出错时执行
   * @param error 错误对象
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {Promise<never>}
   */
  onRejected(error, options) {
    const {
      message
    } = options;
    message.destroy();
    if (error.message.indexOf('401') !== -1) {
      message.destroy();
      message.error('您还未登录或认证 token 已过期，请重新登录');
    } else {
      //相应拦截接口500    只打印不弹框
      console.log(error.message);
      // message.error(error.message);
    }
    return Promise.reject(error);
  },
};

const resp403 = {
  onFulfilled(response, options) {
    const {
      message
    } = options;
    if (response.status === 403) {
      message.destroy();
      message.error(`请求被拒绝`);
    }
    return response;
  },
};

const respprogram200 = {
  onFulfilled(response, options) {
    const {
      message
    } = options;
    const {
      data
    } = response;
    if (response.status === 200) {
      if (
        data.code === 201 ||
        data.code === 401 ||
        data.code === 402 ||
        data.code === 403 ||
        data.code === 404 ||
        data.code === 4000
      ) {
        message.destroy();
        if (data.code === 401) {
          message.error('您还未登录或认证 token 已过期，请重新登录');
        } else if (data.code === 402) {
          // message.error('token缺失');
          message.error('您还未登录或认证 token 已过期，请重新登录');
        } else {
          // message.error(data.msg);
        }
        // if (data.code === 401 || data.code == 404 || data.code === 402) {
        //   window.location.hash = "/login/Login";
        // }
      }
      return response;
    }
    return response;
  },
};

const reqCommon = {
  /**
   * 发送请求之前做些什么
   * @param config axios config
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {*}
   */
  onFulfilled(config) {
    // const {
    //   message,
    //   router
    // } = options;
    // const { message } = options;
    // const {
    //   url,
    //   xsrfCookieName
    // } = config;
    // const newPath = router.history.current.path;
    // === -1  不包含  
    //由于目前暂无登录，以下暂时注释
    // if (newPath.indexOf("numberDetails") === -1) {
    // if (!router.history.current.query.token) {
    //   if (newPath.indexOf("login") === -1) {
    //     if (localStorage.getItem('fromByOther') === 'false') {
    //       setAuthorization({
    //         token: JSON.parse(localStorage.getItem('loginToken')),
    //         expireAt: new Date(JSON.parse(localStorage.getItem('loginexpireAt'))).getTime(),
    //       });
    //     }
    //   }
    // }
    // if (
    //   newPath.indexOf("login") === -1 &&
    //   url.indexOf("login") === -1 &&
    //   xsrfCookieName &&
    //   !Cookie.get(xsrfCookieName) &&
    //   !localStorage.getItem("loginToken")
    // ) {
    //   message.destroy();
    //   message.warning("您还未登录或认证 token 已过期，请重新登录");
    //   window.location.hash = "/login/Login";
    // }
    // }
    //  else {
    //   setAuthorization({
    //     token: "netCnki@qwe123",
    //     expireAt: "",
    //   });
    // }

    return config;
  },
  // onFulfilled(config) {
  //   return config;
  // },
  /**
   * 请求出错时做点什么
   * @param error 错误对象
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {Promise<never>}
   */
  // onRejected(error, options) {
  //   const { message } = options;
  //   message.error(error.message);
  //   return Promise.reject(error);
  // },
};

export default {
  request: [reqCommon], // 请求拦截
  response: [resp401, resp403, respprogram200], // 响应拦截
};