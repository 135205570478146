import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Antd from "ant-design-vue";
import bootstrap from "@/bootstrap";
import "./assets/styles/common.less";
import "./assets/styles/antd-vue.less";
// svg全局组件
import SvgIcon from "./components/svgicon/Index.vue";
// svg文件解析
import "./js/svg";
//echarts 和 echarts-wordcloud
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
require('echarts-wordcloud')

import Router from 'vue-router'
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
return routerPush.call(this, location).catch(error=> error)
}

import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

import zhCN from 'ant-design-vue/es/vc-pagination/locale/zh_CN'
Vue.prototype.zhCN = zhCN;

Vue.config.productionTip = false;
Vue.use(Antd);
Vue.component("svg-icon", SvgIcon);


import tool from "@/utils/tool";
Vue.use(tool);
// router.beforeEach((to, from, next) => {
//   /* 路由发生变化修改页面title */
//   if (to.meta.title) {
//     document.title = to.meta.title
//   }
//   next()
// })
bootstrap({ router, store, message: Vue.prototype.$message });

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
