<template>
  <div class="header">
    <div class="header-bar">
      <div class="container">
        <a-row>
          <a-col :span="8" class="logo">
            <a @click="goRouter()">
              <img src="~@/assets/images/logo.png" alt="" />
            </a>
          </a-col>
          <a-col :span="16" class="header-r">
            <a-input-search
              size="large"
              placeholder=""
              autocomplete="off"
              enter-button
              @search="onSearch"
              v-if="routerName !== 'NewsSearch' && routerName !== 'BookList'"
            />
            <a class="link-mail" @click="goDetails()"><span></span>投稿邮箱</a>
            <a class="link-collect" @click="gocollect()"
              ><span></span>加入收藏</a
            >
            <a class="link-tm" @click="goMall"><span></span>天猫旗舰店</a>
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="nav">
      <div class="container">
        <a-menu v-model="currentPage" mode="horizontal" @select="getCurrent">
          <template v-for="item in themeList">
            <a-menu-item v-if="!item.childrenList" :key="item.id">
              <router-link :to="{ name: item.router }">
                <div class="family-title" style="text-align: center">
                  <span style="text-align: center">{{ item.themename }}</span>
                </div>
              </router-link>
            </a-menu-item>
            <Sub-menu
              @getFirstId="getFirstId"
              ref="childMenu"
              v-else
              :key="item.id"
              :menu-info="item"
            />
          </template>
        </a-menu>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "@/services/home.js";
import SubMenu from "./hearMenu2.vue";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "Header",
  components: {
    SubMenu,
  },
  data() {
    return {
      currentPage: [],
      collapsed: false,
      routerName: "",
    };
  },
  watch: {
    $route: {
      immediate: true, // 一旦监听到路由的变化立即执行
      handler(to) {
        let name = to.name;
        this.routerName = name;
        // console.log(to.params.id)
        if (to.params.id) {
          this.currentPage = [to.params.id];
        }
      },
    },
  },
  computed: {
    ...mapGetters("header", ["themeList", "currentPageNew"]),
  },

  created() {
    this.getAllLevelTheme();
  },
  methods: {
    ...mapMutations("header", ["savethemeList", "savecurrentPage"]),
    getFirstId(id) {
      this.currentPage = [id];
    },
    //跳转首页
    goRouter() {
      this.$router.push({
        name: "Index",
      });
    },
    goMall() {
      window.open("https://zgzyycbs.tmall.com/", "_blank");
    },
    getCurrent(item) {
      this.currentPage = [item.key];
    },
    onSearch(value) {
      let name = this.routerName;
      if (name === "BookIndex" || name === "BookDetail" || name === "Index") {
        this.$router.push({
          name: "BookList",
          params: {
            value: value,
          },
        });
      } else if (name === "EditorIndex") {
        this.$emit("searchEditor", value);
      } else {
        this.$router.push({
          name: "NewsSearch",
          params: {
            value: value,
          },
        });
      }
    },
    goDetails() {
      this.$router.push({
        name: "Contact",
        params: {
          id: "7.4.",
        },
      });
    },
    //添加shuqian
    gocollect() {
      if (window.sidebar) {
        // Firefox
        window.sidebar.addPanel(
          "中国中医药出版社", //书签名
          "http://10.120.66.187:8185/cptcmweb/#/home/index", //书签地址
          ""
        );
      } else {
        if (window.external && "AddFavorite" in window.external) {
          // Internet Explorer
          window.external.AddFavorite(
            "http://10.120.66.187:8185/cptcmweb/#/home/index",
            "中国中医药出版社"
          );
        } else {
          // Opera, Google Chrome and Safari
          alert("加入收藏失败，请使用Ctrl+D进行添加!");
        }
      }
    },
    //添加路由跳转
    setRouter(list) {
      let vm = this;
      list.map(function (data) {
        if (data.id === "1.") {
          //首页
          data.router = "Index";
        } else if (data.id === "6.") {
          //图书展示
          data.router = "BookIndex";
          // console.log(data.childrenList)
          if (data.childrenList) {
            vm.setRouterName(data.childrenList, "BookList");
          }
        } else if (data.id === "2.") {
          //公司简介
          data.router = "CompanyOverview";
          if (data.childrenList) {
            vm.setRouterName(data.childrenList, "CompanyOverview");
          }
        } else if (data.id === "3.") {
          //新闻中心
          data.router = "NewsIndex";
          if (data.childrenList) {
            vm.setRouterName(data.childrenList, "NewsList");
          }
        } else if (data.id === "8.") {
          //旗下品牌
          data.router = "BrandIndex";
          if (data.childrenList) {
            vm.setRouterName(data.childrenList, "BrandIndex");
          }
        } else if (data.id === "11.") {
          //期刊年鉴
          data.router = "JournalIndex";
          if (data.childrenList) {
            vm.setRouterName(data.childrenList, "JournalIndex");
          }
        } else if (data.id === "12.") {
          //编辑风采
          data.router = "EditorIndex";
          delete data.childrenList
          // if (data.childrenList) {
          //   data.childrenList.unshift({
          //     id: "全部",
          //     ordernum: "",
          //     parentid: "12.",
          //     router: "EditorIndex",
          //     themename: "全部",
          //   });
          //   vm.setRouterName(data.childrenList, "EditorIndex");
          // }
        } else if (data.id === "7.") {
          //联系我们
          data.router = "Contact";
          if (data.childrenList) {
            vm.setRouterName(data.childrenList, "Contact");
          }
        }
      });
      return list;
    },
    //递归添加router
    setRouterName(list, name) {
      let vm = this;
      list.map(function (data) {
        data.router = name;
        if (data.themename === "下载专区") {
          data.router = "DownLoadEar";
        }
        if (data.childrenList) {
          vm.setRouterName(data.childrenList, name);
        }
      });
    },
    getAllLevelTheme() {
      if (this.themeList && this.themeList.length === 0) {
        api.getAllLevelTheme("").then((res) => {
          if (res.data.success) {
            let newList = this.setRouter(res.data.data);
            this.savethemeList(newList);
            let name = this.routerName;
            if (name === "Index") {
              //首页
              this.currentPage = ["1."];
            } else if (name === "BookIndex") {
              //图书展示
              // this.currentPage = ["6."];
              this.currentPage = [this.themeList[3].childrenList[0].id];
            } else if (name === "CompanyOverview") {
              //公司概况
              if (this.$route.params.id) {
                this.currentPage = [this.$route.params.id];
              } else {
                this.$router.push({
                  name: "CompanyOverview",
                  params: { id: this.themeList[1].childrenList[0].id },
                });
                this.currentPage = [this.themeList[1].childrenList[0].id];
              }
            } else if (name === "NewsIndex" || name === "NewsDetail") {
              //新闻中心
              this.currentPage = [this.themeList[2].childrenList[0].id];
            } else if (name === "BrandIndex") {
              //旗下品牌
              if (this.$route.params.id) {
                this.currentPage = [this.$route.params.id];
              } else {
                this.$router.push({
                  name: "BrandIndex",
                  params: { id: this.themeList[4].childrenList[0].id },
                });
                this.currentPage = [this.themeList[4].childrenList[0].id];
              }
            } else if (name === "JournalIndex") {
              //期刊年鉴
              if (this.$route.params.id) {
                this.currentPage = [this.$route.params.id];
              } else {
                this.$router.push({
                  name: "JournalIndex",
                  params: { id: this.themeList[5].childrenList[0].id },
                });
                this.currentPage = [this.themeList[5].childrenList[0].id];
              }
            } else if (name === "EditorIndex") {
              //编辑风采
              this.currentPage = ["12."];
              // if (this.$route.params.id) {
              //   this.currentPage = [this.$route.params.id];
              // } else {
              //   this.$router.push({
              //     name: "EditorIndex",
              //     params: { id: this.themeList[6].childrenList[0].id },
              //   });
              //   this.currentPage = [this.themeList[6].childrenList[0].id];
              // }
            } else if (name === "Contact") {
              //关于我们
              if (this.$route.params.id) {
                this.currentPage = [this.$route.params.id];
              } else {
                this.$router.push({
                  name: "Contact",
                  params: { id: this.themeList[7].childrenList[0].id },
                });
                this.currentPage = [this.themeList[7].childrenList[0].id];
              }
            } else if (name === "NewsList") {
              //新闻列表
              if (this.$route.params.id) {
                this.currentPage = [this.$route.params.id];
              } else {
                this.currentPage = [this.themeList[2].childrenList[0].id];
              }
            } else if (name === "BookList") {
              //图书列表
              if (this.$route.params.id) {
                this.currentPage = [this.$route.params.id];
              } else {
                this.currentPage = [this.themeList[3].childrenList[0].id];
              }
            } else if (name === "NewsDetail") {
              this.currentPage = [this.themeList[2].childrenList[0].id];
            } else if (name === "CompanyDetails") {
              this.currentPage = [this.themeList[1].childrenList[0].id];
            } else if (name === "BrandIndexDetails") {
              this.currentPage = [this.themeList[4].childrenList[0].id];
            } else if (name === "JournalIndexDetails") {
              this.currentPage = [this.themeList[5].childrenList[0].id];
            } else if (name === "EditorDetails") {
              this.currentPage = [this.themeList[6].childrenList[0].id];
            } else if (name === "contactDetails") {
              this.currentPage = [this.themeList[7].childrenList[0].id];
            } else if (name === "DownLoadEar") {
              this.currentPage = ["7.3."];
            } else if (name === "BookDetail" || name === "BookList") {
              this.currentPage = ["6."];
            } else if (name === "NewsSearch") {
              this.currentPage = [this.themeList[2].childrenList[0].id];
            }
            // NewsList
            // this.currentPage = [this.themeList[0].id];
          }
        });
      } else {
        let name = this.routerName;
        if (name === "Index") {
          //首页
          this.currentPage = ["1."];
        } else if (name === "BookIndex") {
          //图书展示
          // this.currentPage = ["6."];
          this.currentPage = [this.themeList[3].childrenList[0].id];
        } else if (name === "CompanyOverview") {
          //公司概况
          if (this.$route.params.id) {
            this.currentPage = [this.$route.params.id];
          } else {
            this.$router.push({
              name: "CompanyOverview",
              params: { id: this.themeList[1].childrenList[0].id },
            });
            this.currentPage = [this.themeList[1].childrenList[0].id];
          }
        } else if (name === "NewsIndex") {
          //新闻中心
          this.currentPage = [this.themeList[2].childrenList[0].id];
        } else if (name === "BrandIndex") {
          //旗下品牌
          if (this.$route.params.id) {
            this.currentPage = [this.$route.params.id];
          } else {
            this.$router.push({
              name: "BrandIndex",
              params: { id: this.themeList[4].childrenList[0].id },
            });
            this.currentPage = [this.themeList[4].childrenList[0].id];
          }
        } else if (name === "JournalIndex") {
          //期刊年鉴
          if (this.$route.params.id) {
            this.currentPage = [this.$route.params.id];
          } else {
            this.$router.push({
              name: "JournalIndex",
              params: { id: this.themeList[5].childrenList[0].id },
            });
            this.currentPage = [this.themeList[5].childrenList[0].id];
          }
        } else if (name === "EditorIndex") {
          //编辑风采
          this.currentPage = ["12."];
          // if (this.$route.params.id) {
          //   this.currentPage = [this.$route.params.id];
          // } else {
          //   this.$router.push({
          //     name: "EditorIndex",
          //     params: { id: this.themeList[6].childrenList[0].id },
          //   });
          //   this.currentPage = [this.themeList[6].childrenList[0].id];
          // }
        } else if (name === "JournalIndex") {
          //关于我们
          if (this.$route.params.id) {
            this.currentPage = [this.$route.params.id];
          } else {
            this.$router.push({
              name: "Contact",
              params: { id: this.themeList[7].childrenList[0].id },
            });
            this.currentPage = [this.themeList[7].childrenList[0].id];
          }
        } else if (name === "NewsList") {
          //新闻列表
          if (this.$route.params.id) {
            this.currentPage = [this.$route.params.id];
          } else {
            this.currentPage = [this.themeList[2].childrenList[0].id];
          }
        } else if (name === "BookList") {
          //图书列表
          if (this.$route.params.id) {
            this.currentPage = [this.$route.params.id];
          } else {
            this.currentPage = [this.themeList[3].childrenList[0].id];
          }
        } else if (name === "NewsDetail") {
          this.currentPage = [this.themeList[2].childrenList[0].id];
        } else if (name === "CompanyDetails") {
          this.currentPage = [this.themeList[1].childrenList[0].id];
        } else if (name === "BrandIndexDetails") {
          this.currentPage = [this.themeList[4].childrenList[0].id];
        } else if (name === "JournalIndexDetails") {
          this.currentPage = [this.themeList[5].childrenList[0].id];
        } else if (name === "EditorDetails") {
          this.currentPage = [this.themeList[6].childrenList[0].id];
        } else if (name === "contactDetails") {
          this.currentPage = [this.themeList[7].childrenList[0].id];
        } else if (name === "DownLoadEar") {
          this.currentPage = ["7.3."];
        } else if (name === "BookDetail" || name === "BookList") {
          this.currentPage = ["6."];
        } else if (name === "NewsSearch") {
          this.currentPage = [this.themeList[2].childrenList[0].id];
        }
      }
    },
  },
};
</script>
